import { Box, Grid, Typography } from "@mui/material";

// mui icons
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import servicesImg from '../../assets/services.jpg'

const Services = () => {
    return (
        <Grid sx={{ paddingX: { xs: 0, lg: '150px' }, display: 'flex', flexDirection: 'column', gap: { xs: 2, md: 5 } }}>
            <Grid textAlign={'center'}>
                <Typography sx={{ fontSize: { xs: '25px', md: '50px' }, color: '#01a2d4', fontWeight: 600 }}>Our Services</Typography>
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} textAlign={'justify'}>
                <Typography className="resText">At Pascali Technologies, we understand that in today's fast-paced digital landscape, businesses require more than just products – they need solutions tailored to their unique needs. Our Services Portfolio is a testament to our commitment to providing the expertise, guidance, and support necessary to navigate the ever-evolving world of IT.
                </Typography>
                <Typography className="resText">Our team of seasoned professionals is equipped with a wealth of knowledge and experience in a diverse range of domains. We and our partner firms have a combined strength of 85+ skilled employees. From Automation, which optimizes your business processes, to Facilitation Services, each offering in our portfolio is designed to empower your business.</Typography>
                <Typography className="resText">
                    We recognize that no two businesses are the same, and that's why our services are highly customizable, ensuring they align seamlessly with your specific goals and challenges. Whether you're a startup looking to establish your digital presence or an established enterprise seeking to enhance your existing systems, Pascali Technologies has a solution for you.
                </Typography>
                <Typography className="resText">
                    What truly sets us apart is our unwavering commitment to staying at the forefront of industry trends and emerging technologies. We continuously evolve and expand our Services Portfolio to equip you with the tools and knowledge needed to thrive in the digital age.
                </Typography>
                <Typography className="resText">
                    In this introduction, we invite you to explore our portfolio, each meticulously crafted to empower your business. Join us on a journey through innovation, and discover how Pascali Technologies can be your trusted partner in achieving digital excellence. With us, you're not just investing in services; you're investing in a partnership dedicated to your success in the ever-evolving world of IT.
                </Typography>
            </Grid>
            <img src={servicesImg} alt="servicesImg" />
            <Grid textAlign={'center'}>
                <Typography sx={{ fontSize: { xs: '25px', md: '40px' }, color: '#01a2d4', fontWeight: 600 }}>Our Be-Spoke Services</Typography>
            </Grid>
            <Box display={'flex'} sx={{ gap: 5, flexDirection: { xs: 'column', md: 'row' } }}>
                <Box flex={1} className='servicesCard'>
                    <Box sx={{ backgroundColor: '#FFBF00' }} className='servicesCardHeader'>Automation</Box>
                    <ul style={{
                        listStyleType: 'none',
                    }}>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>API Integration</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>BoT Driver Staff Automation</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Financial Transformation</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>HR Automation</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>IT Automation</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>RPA</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>System Integration</li>
                        </Box>
                    </ul>
                </Box>
                <Box flex={1} className='servicesCard'>
                    <Box sx={{ backgroundColor: '#304556' }} className='servicesCardHeader'>Be-Spoke</Box>
                    <ul style={{
                        listStyleType: 'none',
                    }}>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Enterprise Application Integration</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Inter Enterprise Application Integration</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Application Migration</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Software Engineering</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Software Development</li>
                        </Box>
                    </ul>
                </Box>
                <Box flex={1} className='servicesCard'>
                    <Box sx={{ backgroundColor: '#f6745f' }} className='servicesCardHeader'>Consultancy</Box>
                    <ul style={{
                        listStyleType: 'none'
                    }}>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>System Study, Gap Analysis</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Technical Architecture, High Volume, <br /> Low Latency</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Technical Documentation</li>
                        </Box>

                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Solutioning and RFP creation</li>
                        </Box>

                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Project monitoring and Audit</li>
                        </Box>

                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Source Code Review and Audit</li>
                        </Box>
                    </ul>
                </Box>
            </Box>
            <Box display={'flex'} sx={{ gap: 5,flexDirection: { xs: 'column', md: 'row' }  }}>
                <Box flex={1} className='servicesCard'>
                    <Box sx={{ backgroundColor: '#74A170' }} className='servicesCardHeader'>Data Analytics</Box>
                    <ul style={{
                        listStyleType: 'none'
                    }}>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>BI Dashboard and Visualizations</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Smart Analytics</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>AI/ML enablement</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Data Warehouse and Data Lakes</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Data Migration Advisory</li>
                        </Box>
                    </ul>
                </Box>
                <Box flex={1} className='servicesCard'>
                    <Box sx={{ backgroundColor: '#E1A95F' }} className='servicesCardHeader'>ERP</Box>
                    <ul style={{
                        listStyleType: 'none'
                    }}>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Business Process Workshops</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>As-Is/To-Be Analysis</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>ERP Implementation</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Project Management & System Audits</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon sx={{ fontSize: '10px' }} />
                            <li>Enhancement, Turnkey & Maintenance Projects</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Trainings (Corporate, Beginners)</li>
                        </Box>
                    </ul>
                </Box>
                <Box flex={1} className='servicesCard'>
                    <Box sx={{ backgroundColor: '#86608E' }} className='servicesCardHeader'>Facilitation</Box>
                    <ul style={{
                        listStyleType: 'none'
                    }}>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Recruitment & Virtual CXO Service</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Project Management</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Off-Shore Development Team</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Off-Shore Functional Testing Team</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Off-Shore Development</li>
                        </Box>
                        <Box display={'flex'} sx={{ gap: 1 }}>
                            <DoubleArrowIcon className="servicesListIcon" />
                            <li>Build Operate Transfer team</li>
                        </Box>
                    </ul>
                </Box>
            </Box>
            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} textAlign={'justify'}>
                <Typography className="resText">In conclusion, our Services Portfolio at Pascali Technologies is more than just a list of offerings; it's a promise of transformation, innovation, and unwavering support for your business. We have taken you on a journey through our diverse range of services, each meticulously designed to empower you with the tools, expertise, and solutions needed to thrive in the digital age.
                </Typography>
                <Typography className="resText">At Pascali Technologies, we understand that your success is our success, and our commitment to excellence knows no bounds. We're not just here to provide services; we're here to be your trusted partner in achieving your digital goals, today and into the future.</Typography>
            </Grid>
        </Grid>
    )
}
export default Services;