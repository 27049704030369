// MUI
import { Grid, Typography, Card, CardMedia, CardContent, Box } from "@mui/material";

// import Carousel component
import ImgCarousel from "../../components/Carousel/ImgCarousel";
// import ContentCarousel from "../../components/Carousel/ContentCarousel";

// // MUI icons
// import TvIcon from '@mui/icons-material/Tv';
// import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
// import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
// import CoffeeIcon from '@mui/icons-material/Coffee';
// import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
// import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// // import images 
// import img4 from '../../assets/img4.jpg';
// import img5 from '../../assets/img5.jpg';
// import img6 from '../../assets/img6.jpg';

const Home = () => {
    return (
        <Grid>
            {/* images Carousel */}
            <ImgCarousel />
            {/* section 1 */}

            <Grid sx={{ paddingX: { xs: 0, lg: '150px',  },display: 'flex', flexDirection: 'column', gap: { xs: 2, md: 5 }, marginTop: { xs: 2, md: 5 } }}>
                <Grid textAlign={'justify'} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Typography fontWeight={600} sx={{ fontSize: { xs: '18px', md: '25px' } }}>Welcome to Pascali Technologies Private Limited (India)</Typography>
                    <Typography className="resText">Your destination for cutting-edge products and services. Our online presence showcases our prowess as a comprehensive solutions provider. With strategic offices in London, UK and Mumbai, India. We’ve established a global reach to serve your needs effectively. </Typography>
                    <Typography className="resText">Our development centers in Mumbai, Pune, Gurugram and Noida are hubs of innovation, propelling us forward in creating tailored solutions.</Typography>
                    <Typography className="resText">In a world driven by technology, we believe that the right IT solutions can unlock immense potential. At Pascali Technologies, we're not just keeping pace with the ever-evolving tech landscape; we're leading the charge with creativity, expertise, and a relentless passion for innovation.</Typography>
                    <Typography className="resText">We understand that in today's fast-paced world, staying ahead requires more than just embracing technology; it demands an agile and forward-thinking approach.</Typography>
                    <Typography className="resText">At the core of Pascali Technologies, you'll find a team of tech enthusiasts, problem solvers, and visionaries who thrive on challenges. We're driven by a commitment to excellence and a deep understanding of how IT can drive growth, efficiency, and innovation.</Typography>
                    <Typography className="resText">What sets us apart is our unwavering dedication to our clients. We believe in forging strong, long-lasting partnerships, and we tailor our solutions to meet your unique needs. Your success is our success, and we're here to guide you every step of the way.</Typography>
                </Grid>
                <Typography color={'#01a2d4'} fontSize={'30px'} fontWeight={600}>
                    Our Expertise
                </Typography>
                <Typography className="resText" textAlign={'justify'}>
                    With a deep-seated commitment to excellence, Pascali Technologies specializes in various facets of technology:
                </Typography>
                <Box display={'flex'} sx={{ flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 2, md: 10 } }} textAlign={'justify'}>
                    <Box display={'flex'} flexDirection={'column'} sx={{ gap: { xs: 2, md: 3 }, flex: 5 }}>
                        <Typography className="resText">
                            <b>Platform Deployment:</b> We excel in deploying platforms and services in cloud environments, including Azure, Amazon, and Google cloud. Our adeptness lies in creating seamless experiences that push boundaries. From cloud-based solutions to complex software frameworks, we have the experience and knowledge to make it work seamlessly for you.
                        </Typography>
                        <Typography className="resText">
                            <b>Architectural Brilliance:</b> Pascali Technologies boasts significant experience in architectural design and technical prowess. We create blueprints that guide the development of secure and scalable solutions, ensuring your ideas are built on a solid foundation.
                        </Typography>
                        <Typography className="resText">
                            <b>Integration Magic:</b> We seamlessly bridge the gaps in your digital ecosystem. We specialize in orchestrating the harmonious integration of disparate software applications, ensuring they work together cohesively to streamline your operations and boost productivity.
                        </Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} sx={{ gap: { xs: 2, md: 3 }, flex: 5 }}>
                        <Typography className="resText">
                            <b>Tailored Solutions:</b>  Your needs are unique, and we understand that. Whether you're looking for fixed-cost solutions that bring predictability to your project, cost-effective staffing solutions, or comprehensive Build Operate & Transfer projects, Pascali Technologies has the experience and versatility to make it happen.
                        </Typography>
                        <Typography className="resText">
                            <b>Security First</b>: Your security is our priority. We engineer highly secure platforms that safeguard your data and user interactions, ensuring peace of mind for both you and your customers. Our platform deployment strategies incorporate the latest security protocols.
                        </Typography>
                        <Typography className="resText">
                            <b>Proven Track Record:</b> With a portfolio of numerous software products and platforms developed along with our partner firms, Pascali Technologies track record speaks for itself. Our history of accomplishment is a testament to our dedication to quality and innovation.
                        </Typography>
                    </Box>
                </Box>
                <Grid textAlign={'justify'} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Typography className="resText">Our expertise shines in applications designed for Tax technology, Banking & Finance, Manufacturing, and e-learning Industries. At Pascali Technologies we’re at the forefront of digital transformation, AI/ML, data analytics, enterprise applications integration, inter-enterprise application, application migrations, software engineering, and be-spoke development.</Typography>
                    <Typography className="resText">Whether you're seeking custom software development, IT consulting, cloud solutions, or any other IT services, Pascali Technologies has the expertise and passion to turn your vision into reality.</Typography>
                    <Typography className="resText">Welcome to the future. Welcome to Pascali Technologies!</Typography>
                </Grid>
                {/* section 1.1 */}
                {/* <Grid container textAlign={'center'} padding={3} bgcolor={'white'} sx={{ minHeight: '300px', paddingX: { xs: 3, lg: '150px' } }} alignContent={'center'}>

                <Grid item xs={12} md={4} sx={{ paddingX: { xs: 0, md: 5 }, paddingY: { xs: 2, md: 0 } }}>
                    <TvIcon className="homeIcons" />
                    <Typography className="homeTitle1" sx={{ marginY: '10px' }}>Codeless Slider</Typography>
                    <Typography className="homeTitle2">Custom slider from our team, with the possibility to create a slider with parallax effects animation and theme styled buttons.</Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ paddingX: { xs: 0, md: 5 }, paddingY: { xs: 2, md: 0 } }}>
                    <VideogameAssetIcon className="homeIcons" />
                    <Typography className="homeTitle1" sx={{ marginY: '10px' }}>Drag & Drop</Typography>
                    <Typography className="homeTitle2">Tower has built with drag & drop page builder. Visual composer is our page builder. It’s front-end and back-end version.</Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ paddingX: { xs: 0, md: 5 }, paddingY: { xs: 2, md: 0 } }}>
                    <StackedLineChartIcon className="homeIcons" />
                    <Typography className="homeTitle1" sx={{ marginY: '10px' }}>High Performace</Typography>
                    <Typography className="homeTitle2">More than 10 years experience in building web we are able to provide our customers with the possibility to create the best product.</Typography>
                </Grid>
            </Grid> */}
                {/* section 2.1 - card */}
                {/* <Grid textAlign={'center'} sx={{ paddingX: { xs: 0, lg: '150px' } }}>
                <Typography sx={{ fontSize: { xs: '25px', md: '35px' } }}>Latest Projects</Typography>
                <Typography sx={{ fontSize: { xs: '15px', md: '20px' }, color: 'darkgray', marginBottom: '10px' }}>What we have done?</Typography>
                <Grid container textAlign={'left'} sx={{ minHeight: '300px' }} alignItems={'center'} >
                    <Grid item xs={12} md={4} sx={{ paddingX: { xs: 0, md: 5 }, paddingY: { xs: 2, md: 0 } }}>
                        <Card variant="outlined">
                            <CardMedia
                                component="img"
                                sx={{
                                    objectFit: 'cover'
                                }}
                                image={img4}
                            />
                            <CardContent>
                                <Typography className="homeTitle1">Suitcase Cool Show</Typography>
                                <Typography className="homeTitle2">design development web</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ paddingX: { xs: 0, md: 5 }, paddingY: { xs: 2, md: 0 } }}>
                        <Card variant="outlined">
                            <CardMedia
                                component="img"
                                image={img5}
                            />
                            <CardContent>
                                <Typography className="homeTitle1">Tower In The City</Typography>
                                <Typography className="homeTitle2">design development</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ paddingX: { xs: 0, md: 5 }, paddingY: { xs: 2, md: 0 } }}>
                        <Card variant="outlined">
                            <CardMedia
                                component="img"
                                image={img6}
                            />
                            <CardContent>
                                <Typography className="homeTitle1">Retro Car Desing</Typography>
                                <Typography className="homeTitle2">design development web</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid> */}
                {/* section 3 - Testimonials */}
                {/* <Grid bgcolor={'white'} width={'100%'} textAlign={'center'} sx={{ position: 'relative' }}>
                <Grid sx={{ position: 'absolute', width: '100%', zIndex: 2, top: '30px' }}>

                    <Typography sx={{ fontSize: { xs: '25px', md: '35px' } }} gutterBottom>Testimonials</Typography>
                    <Typography sx={{ fontSize: { xs: '15px', md: '20px' }, color: 'darkgray', marginBottom: '10px' }}>What they say?</Typography>
                </Grid>
                <ContentCarousel />
            </Grid> */}
                {/* section 4 - our services */}
                {/* <Grid container sx={{ paddingX: { xs: 0, lg: '150px' } }}>
                <Grid item xs={12} md={6} bgcolor={'white'} sx={{ padding: { xs: 2, lg: 4 } }} display={'flex'} flexDirection={'column'} gap={3}>
                    <Grid item xs={12} md={12} bgcolor={'white'} display={'flex'} sx={{ flexDirection: { xs: 'column', md: 'row' } }} gap={2}>
                        <Grid item xs={12} md={6} display={'flex'} gap={1}>
                            <CoffeeIcon sx={{ color: '#01a2d4', marginTop: { xs: '2px', lg: '5px' } }} />
                            <Grid>
                                <Typography className="homeTitle1" gutterBottom>PAGE BUILDER</Typography>
                                <Typography className="homeTitle2">Tower has built with drag & drop front-end and backend page builder.</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} display={'flex'} gap={1}>
                            <SignalCellularAltIcon sx={{ color: '#01a2d4', marginTop: { xs: '2px', lg: '5px' } }} />
                            <Grid>
                                <Typography className="homeTitle1" gutterBottom>SIDE NAVIGATION</Typography>
                                <Typography className="homeTitle2">Add new widgetized area expandable with a button for your clients.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} bgcolor={'white'} display={'flex'} sx={{ flexDirection: { xs: 'column', md: 'row' } }} gap={2}>
                        <Grid item xs={12} md={6} display={'flex'} gap={1}>
                            <CameraAltIcon sx={{ color: '#01a2d4', marginTop: { xs: '2px', lg: '5px' } }} />
                            <Grid>
                                <Typography className="homeTitle1" gutterBottom>PARALLAX SECTIONS</Typography>
                                <Typography className="homeTitle2">Create parallax sections with animations. Use parallax on inner page.</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} display={'flex'} gap={1}>
                            <LocalOfferIcon sx={{ color: '#01a2d4', marginTop: { xs: '2px', lg: '5px' } }} />
                            <Grid>
                                <Typography className="homeTitle1" gutterBottom>WOOCOMMERCE</Typography>
                                <Typography className="homeTitle2">Create your online shop and sell your products now.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} padding={3} bgcolor={'#01a2d4'} textAlign={'center'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: { xs: '25px', md: '35px' }, color: 'white' }}>Our Core Services</Typography>
                    <Typography sx={{ fontSize: { xs: '15px', md: '20px' }, color: 'white' }}>The new way of doing web</Typography>
                </Grid>
            </Grid> */}
                {/* <Grid sx={{ display: 'flex', width: '100%', flexDirection: { md: 'row', xs: 'column' }, gap: 2, justifyContent: 'end', alignItems: 'center' }}>
                    <Typography>Visitor Count: </Typography>
                    <Box className='visiotCountBox'>
                        <Box className='VisitorCounter'>0</Box>
                        <Box className='VisitorCounter'>0</Box>
                        <Box className='VisitorCounter'>0</Box>
                        <Box className='VisitorCounter'>0</Box>
                        <Box className='VisitorCounter'>0</Box>
                        <Box className='VisitorCounter'>0</Box>
                        <Box className='VisitorCounter'>0</Box>
                    </Box>
                </Grid> */}
            </Grid>
        </Grid>
    )
}
export default Home;