import { Box, Divider, Grid, Typography } from "@mui/material";

import img1 from '../../assets/team-img1.jpeg';
import img2 from '../../assets/SKS.png';
import vision from '../../assets/vision.jpg';
import mission from '../../assets/mission.jpg';
const AboutUs = () => {
    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 2, md: 5 } }}>
            <Grid textAlign={'center'}>
                <Typography sx={{ fontSize: { xs: '25px', md: '50px' }, color: '#01a2d4', fontWeight: 600 }}>About Us</Typography>
            </Grid>
            <Grid sx={{ paddingX: { xs: 0, lg: '150px' }, display: 'flex', flexDirection: 'column', gap: 2 }} textAlign={'justify'}>
                <Typography className="resText">Pascali Technologies Private Limited is at the forefront of innovation, founded to transform Tax technology. Our D'TX Suite is a comprehensive platform with SaaS-based products to address organisations unique Tax challenges. Companies are under more scrutiny and have shorter response times due to rapid Tax process digitalization. This is why our production solutions addresses both Compliance and Tax laws.
                </Typography>
                <Typography className="resText">At the heart of our startup is a burning passion for technology, a relentless pursuit of excellence, and an unwavering commitment to transforming the way businesses harness the power of IT.</Typography>
                <Typography className="resText">
                    We envision a world where technology isn't just a tool but a catalyst for growth, efficiency, and innovation. We see a future where businesses, regardless of their size or industry, can harness the full potential of digital solutions to thrive and evolve.
                </Typography>
            </Grid>
            <Grid textAlign={'center'}>
                <Typography sx={{ fontSize: { xs: '25px', md: '40px' }, color: '#01a2d4', fontWeight: 600 }}>OUR TEAM</Typography>
            </Grid>
            <Grid sx={{ paddingX: { xs: 0, lg: '150px' }, display: 'flex', flexDirection: 'column', gap: 2 }} textAlign={'justify'} >
                <Typography className="resText">Sannjay Sharma and Sailesh M. Pande, the driving forces behind the Pascali Technologies, are not just entrepreneurs; they are visionary leaders with a deep passion for technology. Their journey began with a shared dream to revolutionize the IT industry and create solutions that would not only meet today's demands but also shape tomorrow's possibilities.
                </Typography>
                <Typography className="resText">With their combined expertise in relevant fields, Sannjay and Sailesh are harnessing their collective knowledge to propel the Pascali Technologies into the spotlight. Their dedication to innovation, pursuit of excellence, and commitment to customer satisfaction is relentless.
                </Typography>
                <Typography className="resText">
                    Together, these visionary founders have embarked on an exhilarating journey, aiming to transform the way we perceive and interact with technology. Their collaborative spirit, combined with their unwavering dedication, serves as the bedrock upon which Pascali Technologies stands.
                </Typography>
                <Typography className="resText">
                    Join us as we delve into the remarkable journey of these two visionaries, exploring their backgrounds, their vision for the Company, and their unwavering determination to make a lasting impact on the arena of technology. Together, they aspire to shape the digital landscape and lead Pascali Technologies to scale the heights of success.
                </Typography>
            </Grid>
            <Grid sx={{ paddingX: { xs: 0, lg: '150px' }, display: 'flex', gap: { xs: 3, md: 4 }, flexDirection: { xs: 'column', md: 'row' } }}>
                <Grid>
                    <Grid textAlign={'center'}>
                        <Typography sx={{ fontSize: { xs: '25px', md: '35px' }, color: 'black' }}>Sannjay Sharma
                        </Typography>
                    </Grid>
                    <Grid sx={{ display: { xs: 'unset', md: 'flex' } }}>
                        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: { xs: 'center', md: 'end' }, pr: { xs: 0, md: 3 } }} p={1} flex={1}>
                            <img src={img2} className="teamImages" alt="Sannjay Sharma" />
                            <Box display={'flex'} sx={{textAlign:{xs : 'center',md :'right'}}}  flexDirection={'column'}>
                                <Typography fontWeight={600} className="resText">Founder & Director</Typography>
                                <Typography color={'#01a2d4'} fontWeight={500} className="resText">Pascali Technologies Pvt. Ltd.</Typography>
                                <Typography className="resText">Mumbai, India</Typography>
                            </Box>
                            <Divider />
                            <Box display={'flex'} sx={{textAlign:{xs : 'center',md :'right'}}}  flexDirection={'column'}>
                                <Typography fontWeight={600} className="resText">Academic Details</Typography>
                                <Typography className="resText">MBA (Finance & Marketing)</Typography>
                                <Typography className="resText">Bachelor of commerce</Typography>
                                <Typography className="resText">PMP – certification</Typography>
                            </Box>
                            <Divider />
                            <Box display={'flex'} sx={{textAlign:{xs : 'center',md :'right'}}}  flexDirection={'column'}>
                                <Typography fontWeight={600} className="resText">Tech & Domain</Typography>
                                <Typography className="resText">BFSI, MFG, Card Processing,</Typography>
                                <Typography className="resText">E-Learning, Tax Tech, GDC</Typography>
                            </Box>
                        </Grid>
                        <Grid sx={{ padding: { xs: 0, md: '10px' } }} textAlign={'justify'} flex={1.3}>
                            <Typography sx={{ color: 'rgb(113, 113, 113);' }} className="resText">Accomplished Technocrat with rich 24 years of experience in IT industry. Adept at overseeing all facets of product & project lifecycle, Experienced in driving strategic direction, product roadmaps, and IT projects through the complexities of diﬀering agendas, personalities, and cultures.
                                Articulate communicator who can ﬂuently speak the language of both people and business, blending functional expertise with interpersonal skills while interacting with the cross-functional teams.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid textAlign={'center'}>
                        <Typography sx={{ fontSize: { xs: '25px', md: '35px' }, color: 'black' }}>Sailesh M. Pande</Typography>
                    </Grid>
                    <Grid sx={{ display: { xs: 'unset', md: 'flex' } }}>
                        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: { xs: 'center', md: 'end' }, pr: { xs: 0, md: 3 } }} p={1} flex={1}>
                            <img src={img1} className="teamImages" alt="Sailesh M. Pande" />
                            <Box display={'flex'} sx={{textAlign:{xs : 'center',md :'right'}}} flexDirection={'column'}>
                                <Typography fontWeight={600} className="resText">Founder & Director</Typography>
                                <Typography color={'#01a2d4'} fontWeight={500} className="resText">Pascali Technologies Pvt. Ltd.</Typography>
                                <Typography className="resText">Mumbai, India</Typography>
                            </Box>
                            <Divider />
                            <Box display={'flex'} sx={{textAlign:{xs : 'center',md :'right'}}} flexDirection={'column'}>
                                <Typography fontWeight={600} className="resText">Academic Details</Typography>
                                <Typography className="resText">MMM (Marketing)</Typography>
                                <Typography className="resText">BE (Electronics)</Typography>
                            </Box>
                            <Divider />
                            <Box display={'flex'} sx={{textAlign:{xs : 'center',md :'right'}}} flexDirection={'column'}>
                                <Typography fontWeight={600} className="resText">Domain</Typography>
                                <Typography className="resText">Industrial Automation</Typography>
                                <Typography className="resText">Business Development,</Typography>
                                <Typography className="resText">Sales & Marketing</Typography>
                            </Box>
                        </Grid>
                        <Grid sx={{ padding: { xs: 0, md: '10px' } }} textAlign={'justify'} flex={1.5}>
                            <Typography sx={{ color: 'rgb(113, 113, 113);' }} className="resText">A highly motivated, confident Business Management, Business Development and Sales professional having 25 years of rich and varied Industry experience. Spearheaded new product launches and ensured targets attainment through long-term & short-term planning. Skilled in sales & business development based on market research and networking. Strategic mindset with strong business orientation. Excellent skills in team building, troubleshooting capability and proven abilities in planning, strategizing and managing resources.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ paddingX: { xs: 0, lg: '150px' } }} textAlign={'justify'}>
                <Typography className="resText">
                    Our diverse & growing team of 85+ professionals from different locations brings together expertise from various corners of the tech universe, from coding wizards to user experience maestros, all united by a common goal: to make technology work for you.
                </Typography>
            </Grid>
            <Grid textAlign={'center'}>
                <Typography sx={{ fontSize: { xs: '25px', md: '40px' }, color: '#01a2d4', fontWeight: 600 }}>VISION & MISSION</Typography>
            </Grid>
            <Grid sx={{ paddingX: { xs: 0, lg: '150px' }, display: 'flex', gap: { xs: 3, md: 5 }, flexDirection: { xs: 'column', md: 'row' } }}>
                <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid textAlign={'center'} mb={1}>
                        <Typography sx={{ fontSize: { xs: '25px', md: '35px' }, color: 'black' }}>Vision</Typography>
                    </Grid>
                    <img src={vision} className="vision-mission-img" alt="vision" />
                    <Grid sx={{ padding: { xs: 0, md: '10px' } }} textAlign={'justify'}>
                        <Typography sx={{ marginTop: 2 }} className="resText">"To become the leading provider of cutting-edge IT & Tax technology solutions that redefine the way businesses manage tax compliance, litigation, and overall financial processes."
                        </Typography>
                    </Grid>
                </Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid textAlign={'center'} mb={1}>
                        <Typography sx={{ fontSize: { xs: '25px', md: '35px' }, color: 'black' }}>Mission</Typography>
                    </Grid>
                    <img src={mission} className="vision-mission-img" alt="mission" />
                    <Grid sx={{ padding: { xs: 0, md: '10px' } }} textAlign={'justify'}>
                        <Typography sx={{ marginTop: 2 }} className="resText">"Empowering businesses with innovative and comprehensive tax technology solutions to streamline compliance, enhance case management, and drive operational efficiency."
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default AboutUs;