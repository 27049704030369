// react-router-dom

import { BrowserRouter } from "react-router-dom";
// import all routes
import Routes from './routes';

//css
import './App.css'
function App() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter >
  );
}

export default App;
