// MUI
import { Grid, Typography, Box } from "@mui/material"

import mobileImg from '../../assets/mobileImg.png'

const Portfolio = () => {
    return (
        <Grid sx={{ paddingX: { xs: 0, lg: '150px' }, display : 'flex', flexDirection : 'column', gap: { xs : 2, md : 5 }}} textAlign={'justify'}>
            <Grid textAlign={'center'}>
                <Typography sx={{ fontSize: { xs: '25px', md: '50px' }, color: '#01a2d4', fontWeight: 600 }}>Our Products</Typography>
            </Grid>
            <Box sx={{ gap: 2, marginBottom: 5 }} display={'flex'} flexDirection={'column'}>
                <Typography className="resText">
                    At Pascali Technologies, we are at the forefront of innovation, harnessing the power of fifth-generation technology to bring your ideas to life. Our expertise lies in crafting cutting-edge Web and Mobile applications that redefine industry standards. With a profound understanding of both server and serverless environments in the cloud, we bring your visions to reality with finesse and precision.
                </Typography>
                <Typography className="resText">
                Welcome to the forefront of technological excellence, where innovation meets practicality, and solutions are crafted with precision. Allow us to introduce you to the comprehensive product suite of Pascali Technologies, a cutting-edge IT company committed to delivering transformative solutions that empower your business.
                </Typography>
                <Typography className="resText">
                At Pascali Technologies, we understand that the digital landscape is constantly evolving, presenting both opportunities and challenges. That's why we've meticulously curated a suite of products designed to not only meet your current needs but also anticipate the demands of tomorrow.
                </Typography>
                <Typography className="resText">
                Our product suite is more than just a collection of tools; it's a reflection of our commitment to excellence and our unwavering dedication to your success. Whether you're a small startup or a large enterprise, our solutions are tailored to enhance your efficiency, security, and competitiveness in an increasingly digital world.
                </Typography>
                <Typography className="resText">
                In this introduction, we invite you to explore the individual components of our product suite, each a testament to our commitment to excellence and our passion for technology. Join us on a journey through innovation and discover how Pascali Technologies can empower your business with our state-of-the-art products. Get ready to embrace the future with confidence, because with Pascali Technologies, you're not just investing in products; you're investing in a partnership that's dedicated to your success.
                </Typography>
            </Box>
            <Grid sx={{ display: 'flex', flexWrap: 'wrap', gap: { xs: 5, md: 10 }, alignItems: 'center', justifyContent: 'center' }}>
                <Grid>
                    <Grid className="porfolioGrid">
                        <img src={mobileImg} alt="D’LITTMAN" />
                        <Grid className="mobileTextGrid">
                            <Typography className="mobileText1" fontWeight={600}>D’LittMan</Typography>
                            <Typography className="mobileText2">Tax Litigation Manager</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid className="porfolioGrid">
                        <img src={mobileImg} alt="D’ CORE" />
                        <Grid className="mobileTextGrid">
                            <Typography className="mobileText1" fontWeight={600}>D’Core</Typography>
                            <Typography className="mobileText2">Compliance & Return Filing</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid className="porfolioGrid">
                        <img src={mobileImg} alt="D’ARC" />
                        <Grid className="mobileTextGrid">
                            <Typography className="mobileText1" fontWeight={600}>D’ARC</Typography>
                            <Typography className="mobileText2">Documentation, Automation, Reconciliation and Compliance Tracker</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid className="porfolioGrid">
                        <img src={mobileImg} alt="D’PAPOR" />
                        <Grid className="mobileTextGrid">
                            <Typography className="mobileText1" fontWeight={600}>D’PaPor</Typography>
                            <Typography className="mobileText2">Partner Portal-Vendor Mgmt., Claim Mgmt.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid className="porfolioGrid">
                        <img src={mobileImg} alt="D’ASPIRE" />
                        <Grid className="mobileTextGrid">
                            <Typography className="mobileText1" fontWeight={600}>D’Aspire</Typography>
                            <Typography className="mobileText2">Auditing, Risk Advisory, Deal Advisory related solutions</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid className="porfolioGrid">
                        <img src={mobileImg} alt="D’IMPERIAL" />
                        <Grid className="mobileTextGrid">
                            <Typography className="mobileText1" fontWeight={600}>D’Imperial</Typography>
                            <Typography className="mobileText2">TUAE-E-Inv, other countries compliance</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ gap: 2 }} display={'flex'} flexDirection={'column'}>
                <Typography color={'#01a2d4'} fontSize={'25px'} fontWeight={600}>
                    Building for the Future
                </Typography>
                <Typography className="resText">
                    At Pascali, we believe in creating solutions that are not only robust but also scalable, high-performing, and available around the clock. Our architectural approach is grounded in scalability, high performance, low latency, and unwavering availability, ensuring our products are always ready to meet demand.
                </Typography>
            </Box>
        </Grid>
    )
}
export default Portfolio;