// Nav Bar component

// top level import
import * as React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// MUI
import { AppBar, CssBaseline, Box, Divider, Toolbar, IconButton, Button, Drawer } from "@mui/material";

// MUI icons
import MenuIcon from '@mui/icons-material/Menu';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

// logo
import pi_logo_1 from '../../assets/pi_logo_1.png';

// react-router-dom
import { useLocation, useNavigate } from 'react-router-dom';

// navItems
const navItems = [
    { name: 'Home', pathname: 'home' },
    { name: 'About us', pathname: 'about-us' },
    { name: 'Portfolio', pathname: 'portfolio' },
    { name: 'Services', pathname: 'services' },
    { name: 'Contact us', pathname: 'contact-us' },
    { name: 'Careers', pathname: 'careers' },
];

function Navbar(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box sx={{ textAlign: 'center' }}>
            <Box display={'flex'} sx={{ justifyContent: 'center', width: { xs: '100%', md: 'unset' } }}>
                <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ display: { md: 'none' }, position: 'absolute', left: 0, top: '1%', maxWidth: '100px !important' }}
                >
                    <KeyboardDoubleArrowUpIcon style={{ color: '#01a2d4', fontSize: '30px' }} />
                </IconButton>

                <img src={pi_logo_1} className='piLogo' alt="logo" style={{ cursor: 'pointer' }} onClick={() => { navigate("/app/home");handleDrawerToggle() }} />
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', gap: 0, flexWrap: 'wrap', flexDirection: 'column', my: 1 }} className='menuDrawerMobile'>
                {navItems.map((item) => (
                    item.name != 'Careers' ?
                        <Button key={item.name} className='nav-btn' sx={{
                            borderRadius: 'unset !important',
                            color: location.pathname.includes(item.pathname) ? '#01a2d4 !important' : null
                        }} onClick={() => { navigate(`/app/${item?.pathname}`);handleDrawerToggle() }}>
                            {item.name}
                        </Button> :
                        <Button key={item.name} className='nav-btn' sx={{
                            borderRadius: 'unset !important',
                            color: location.pathname.includes(item.pathname) ? '#01a2d4 !important' : null
                        }} onClick={() => { navigate(`/app/${item?.pathname}`);handleDrawerToggle() }}>
                            {item.name}
                        </Button>

                ))}
            </Box>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" position='fixed' className='navBar'>
                    <Toolbar className='appBar'>
                        <Box display={'flex'} sx={{ justifyContent: 'center', width: { xs: '100%', md: 'unset' } }}>

                            <IconButton
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ display: { md: 'none' }, position: 'absolute', left: 0, top: '8%', maxWidth: '100px !important' }}
                            >
                                <KeyboardDoubleArrowDownIcon style={{ color: '#01a2d4', fontSize: '30px' }} />
                            </IconButton>
                            <img src={pi_logo_1} className='piLogo' alt="logo" style={{ cursor: 'pointer' }} onClick={() => { navigate("/app/home") }} />
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 1 }}>
                            {navItems.map((item) => (
                                item.name != 'Careers' ?
                                    <Button key={item.name} className='nav-btn' sx={{
                                        borderRadius: 'unset !important',
                                        color: location.pathname.includes(item.pathname) ? '#01a2d4 !important' : null
                                    }} onClick={() => { navigate(`/app/${item?.pathname}`) }}>
                                        {item.name}
                                    </Button> :
                                    <>
                                        <Divider orientation='vertical' flexItem />
                                        <Button key={item.name} className='nav-btn' sx={{
                                            borderRadius: 'unset !important',
                                            color: location.pathname.includes(item.pathname) ? '#01a2d4 !important' : null
                                        }} startIcon={<ShowChartIcon style={{ color: '#01a2d4' }} />} onClick={() => { navigate(`/app/${item?.pathname}`) }}>
                                            {item.name}
                                        </Button>
                                    </>
                            ))}
                        </Box>
                    </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    anchor='top'
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}

Navbar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Navbar;
