// MUI
import { Grid, Typography, IconButton, Box } from "@mui/material";

// MUI icons
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
// logo
import pi_logo_1 from '../../assets/pi_logo_1.png';
import pi_logo_2 from '../../assets/pi_logo_2.png';

// react-router
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate()
    return (
        <Grid container padding={2} sx={{ minHeight: '300px', paddingX: { xs: 1, lg: '150px' }, mt: {xs : 2, md : 5}, borderTop: '1px solid #01a2d4' }} alignContent={'center'} textAlign={'justify'}>
            <Grid item xs={12} md={4} sx={{ paddingX: { xs: 0, md: 3 }, paddingY: { xs: 2, md: 0 } }}>
                <img src={pi_logo_1} width={'120px'} alt="logo" style={{ cursor: 'pointer' }} onClick={() => { navigate("/app/home") }} />
                <Typography className="homeTitle2" gutterBottom>Welcome to Pascali Technologies, where innovation meets technology to redefine the future. We are not just another IT company; we are a dynamic force driven by a passion for cutting-edge solutions and a relentless pursuit of excellence.</Typography>
                <Typography className="homeTitle2" gutterBottom>Join us on this exciting journey as we pave the way for a brighter, tech-driven tomorrow. Whether you're a start-up looking to disrupt the market or an established enterprise seeking to innovate, Pascali Technologies is your trusted partner in IT excellence. Together, we will shape the future of technology, one solution at a time.</Typography>
                <Grid display={'flex'} gap={2} marginTop={2}>
                    <IconButton className="footerIcon">
                        <TwitterIcon sx={{ fontSize: { xs: '15px', lg: '20px' } }} />
                    </IconButton>
                    <IconButton className="footerIcon">
                        <InstagramIcon sx={{ fontSize: { xs: '15px', lg: '20px' } }} />
                    </IconButton>
                    <IconButton className="footerIcon">
                        <LinkedInIcon sx={{ fontSize: { xs: '15px', lg: '20px' } }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} sx={{ paddingX: { xs: 0, md: 3 }, paddingY: { xs: 2, md: 2 } }} gap={1} display={'flex'} flexDirection={'column'}>
                <Typography fontSize={'22px'} sx={{marginBottom : '12px'}}>Latest News</Typography>
                <Typography className="homeTitle2">Government Imposes 30-Day Invoice Reporting Limit for Large Taxpayers</Typography>
                <Typography className="homeTitle2">The Indian Government has introduced a significant change in the reporting of invoices on the e-invoice IRP portals. Taxpayers with an Aggregate Annual Turnover (AATO) exceeding 100 crores will now face a strict time limit for reporting invoices.</Typography>
                <Typography className="homeTitle2">Read more at: <a href="https://taxguru.in/goods-and-service-tax/government-imposes-30-day-invoice-reporting-limit-large-taxpayers.html" target="_blank">https://taxguru.in/goods-and-service-tax/government-imposes-30-day-invoice-reporting-limit-large-taxpayers.html</a>
                    <br/>Copyright © Taxguru.in
                </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ paddingX: { xs: 0, md: 3 }, paddingY: { xs: 2, md: 2 } }}>
                <Typography fontSize={'22px'}>Contact Info</Typography>
                <Box display={'flex'} alignItems={'center'} sx={{ gap: 1 }}>
                    <img src={pi_logo_2} width={'50px'} alt="logo" style={{ cursor: 'pointer' }} onClick={() => { navigate("/app/home") }} />
                    <Typography fontSize={'16px'}>Pascali Technologies Private Limited</Typography>
                </Box>

                <Grid display={'flex'} alignItems={'center'} gap={1} marginTop={'10px'}>
                    <LocationOnIcon className="homeTitle2" />
                    <Typography className="homeTitle2">SH 4, Plot No. 66, Gorai Sai Gharkul CHS.,
                        Opp. Pepsi Ground, Gorai 2, Borivali West,
                        Mumbai 400 092, Maharashtra</Typography>
                </Grid>
                <Grid display={'flex'} alignItems={'center'} gap={1} marginY={'10px'}>
                    <LocalPhoneIcon className="homeTitle2" />
                    <Typography className="homeTitle2">+91 22 3558 7031, +91 88288 90285</Typography>
                </Grid>
                <Grid display={'flex'} alignItems={'center'} gap={1}>
                    <EmailIcon className="homeTitle2" />
                    <Typography className="homeTitle2">info@pascali.in</Typography>
                </Grid>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15068.687559080327!2d72.8244991!3d19.2313391!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b127d5aa76ad%3A0x8583203d7ec76e16!2sPascali%20Technologies!5e0!3m2!1sen!2sin!4v1694429542387!5m2!1sen!2sin" style={{ width: '100%', marginTop: '10px', border: '2px solid darkgrey' }}></iframe>
            </Grid>
            <Grid marginTop={1} sx={{ paddingX: { xs: 0, md: 3 }, display : 'flex', flexDirection : 'column', gap : {xs : 1, md : 0} }}>
                <Typography fontSize={'13px'}>Copyrights {new Date().getFullYear()} &copy; Pascali Technologies Private Limited - All Rights Reserved.</Typography>
                <Typography fontSize={'13px'}>Designed & Developed by - Pascali Technologies Private Limited</Typography>
            </Grid>
        </Grid>
    )
}
export default Footer;