import { Box, Button, Grid, Typography } from "@mui/material";

import reactJD from '../../assets/JobOpening/React Js Developer JD.pdf';
import phpJD from '../../assets/JobOpening/PHP Laravel Developer JD.pdf';
import pythonJD from '../../assets/JobOpening/Python Developer JD.pdf';


import careerImg from '../../assets/career.jpg'
const Career = () => {
    const jobs = [
        {
            title: 'React Js Developer',
            role: 'Front End Developer',
            location: 'Mumbai Suburbs',
            experience: '2 - 4 years',
            noOfPosition : 1,
            employmentType: 'Full Time, Permanent',
            jobDescription: reactJD
        },
        {
            title: 'PHP Laravel Developer',
            role: 'Full Stack Developer',
            location: 'Mumbai Suburbs',
            experience: '3 - 5 years',
            noOfPosition : 2,
            employmentType: 'Full Time, Permanent',
            jobDescription: phpJD
        },
        {
            title: 'Python Developer',
            role: 'Full Stack Developer',
            location: 'Mumbai Suburbs',
            experience: '3 - 5 years',
            noOfPosition : 2,
            employmentType: 'Full Time, Permanent',
            jobDescription: pythonJD
        },
    ]
    return (
        <Grid sx={{ paddingX: { xs: 0, lg: '150px' }, display: 'flex', flexDirection: 'column', gap: { xs: 2, md: 5 } }}>
            <Grid textAlign={'center'}>
                <Typography sx={{ fontSize: { xs: '25px', md: '50px' }, color: '#01a2d4', fontWeight: 600 }}>Careers</Typography>
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} textAlign={'justify'}>
                <Typography className="resText">As an IT company at the forefront of technology and innovative products & solutions, we believe that our greatest asset is our talented and passionate team. If you're driven by a desire to shape the future of technology and embark on a rewarding journey of growth and achievement, you've come to the right place.
                </Typography>
                <Typography className="resText">
                    At Pascali Technologies, we foster an environment that nurtures creativity, values diversity, and encourages continuous learning. As such, we're constantly on the lookout for exceptional individuals who are ready to join us in pushing the boundaries of what's possible in the digital realm.
                </Typography>
                <Typography className="resText">
                    Explore our current job openings, and let's explore the future together. Join us at Pascali Technologies and be a part of our dynamic team.
                </Typography>
                <Typography className="resText">
                    Your journey starts here…
                </Typography>
            </Grid>
            <img src={careerImg} alt="career" style={{ maxHeight: 550 }} />
            <Grid textAlign={'center'}>
                <Typography sx={{ fontSize: { xs: '25px', md: '40px' }, color: '#01a2d4', fontWeight: 600 }}>OPEN POSITIONS</Typography>
            </Grid>
            <Box display={'flex'} flexWrap={'wrap'} sx={{ gap: 5, flexDirection: { xs: 'column', md: 'row' } }} justifyContent={'center'}>
                {
                    jobs?.map((job) => (
                        <Box className='jobCard'>
                            <Box sx={{ backgroundColor: '#01a2d4' }} className='jobCardHeader'>{job.title}</Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, paddingX: { xs: '10px', md: '20px' } }} pt={2}>
                                <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} textAlign={''}>
                                    <Typography className="resText"><b>Role</b>: {job.role}</Typography>
                                    <Typography className="resText"><b>Location</b>: {job.location}</Typography>
                                    <Typography className="resText"><b>Experience</b>: {job.experience}</Typography>
                                    <Typography className="resText"><b>No of Positions</b>: {job.noOfPosition}</Typography>
                                    <Typography className="resText"><b>Employment Type</b>: {job.employmentType}</Typography>
                                </Grid>
                                <Box display={'flex'} justifyContent={'center'} >
                                    <Button component='a' href={job.jobDescription} target="_blank" variant="contained" sx={{ backgroundColor: "#01a2d4 !important", textTransform: 'none', fontSize: { xs: '10px', md: '13px' }, fontWeight: 600 }} >View Job</Button>
                                </Box>
                            </Box>
                        </Box>
                    ))
                }
            </Box>
            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} textAlign={'justify'}>
                <Typography className="resText">If you're excited about the prospect of working with us, we want to hear from you! Please send your resume and a cover letter detailing your skills, experience, and why you're interested in joining Pascali Technologies to <span style={{color : '#01a2d4'}}>info@pascali.in</span></Typography>
                <Typography className="resText">At Pascali Technologies, we celebrate diversity and believe in equal opportunities for all. We encourage candidates of all backgrounds, experiences, and perspectives to apply.</Typography>
                <Typography className="resText">Don't see a position that matches your skills? Feel free to reach out anyway. We're always on the lookout for exceptional talent, and your unique abilities might be exactly what we need to take our team to the next level.</Typography>
                <Typography className="resText">Join us in shaping the future of technology. Contact Pascali Technologies today, and let's build something extraordinary together.</Typography>
            </Grid>
        </Grid>
    )
}
export default Career;