// includes navbar/top component & footer component

// top level import
import React from "react";

// import NavBar component
import NavBar from "../../components/Navbar/Navbar";

// MUI
import { Grid, CssBaseline} from "@mui/material";

// react-router-dom
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";


const PiWebLayout = () => {
    
    const { pathname } = useLocation();

    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <Grid container maxHeight={'100vh'} width={'100%'}>
            <CssBaseline />
            <NavBar /> {/* top navigation bar */}

            {/* all children will be render here */}
            <Grid sx={{ marginTop: { xs: '58px', sm: '70px' }, padding: { xs: '10px', sm: '20px' } }} width={'100%'}>
                <Outlet /> {/* all children will render/show here */}
            </Grid>
            <Footer /> {/* bottom/footer section */}
        </Grid>
    )
}

export default PiWebLayout;