import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// css
import './stylesImgCarousel.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// import images
import img1 from '../../assets/home-1.jpg';
import img2 from '../../assets/home-2.jpg';
import img3 from '../../assets/home-3.jpg';
import img4 from '../../assets/home-4.jpg';
import { Grid, Typography } from '@mui/material';

export default function Carousel() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="myImgContainer"
      >
        <SwiperSlide>
          <Grid className='img-text-div'>
            <img src={img1} alt='Welcome to Pascali Technologies Private Limited...'/>
            <Typography className='img-text'>Welcome to Pascali Technologies Private Limited...</Typography>
          </Grid>
        </SwiperSlide>
        <SwiperSlide>
          <Grid className='img-text-div'>
            <img src={img2} alt='Bring your Ideas to Life...'/>
            <Typography className='img-text'>Bring your Ideas to Life...</Typography>
          </Grid>
        </SwiperSlide>
        <SwiperSlide>
          <Grid className='img-text-div'>
            <img src={img3} alt='Embrace The Future...'/>
            <Typography className='img-text'>Embrace The Future...</Typography>
          </Grid>
        </SwiperSlide>
        <SwiperSlide>
          <Grid className='img-text-div'>
            <img src={img4} alt='Revolutionizing Tax Compliance and Litigation Management...'/>
            <Typography className='img-text'>Revolutionizing Tax Compliance and Litigation Management...</Typography>
          </Grid>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
