// Application routes

// react-router-dom
import { Navigate, useRoutes } from "react-router-dom";

// import pages
// layout
import PiWebLayout from "../layout/PiWebLayout/PiWebLayout";

// page not found
import PageNotFound from "../pages/PageNotFound/PageNotFound";
// Home
import Home from "../pages/Home/Home";
// about us
import AboutUs from "../pages/AboutUs/AboutUs";
// portfolio
import Portfolio from "../pages/Portfolio/Portfolio";
// Services
import Services from "../pages/Services/Services";
// contact us
import ContactUs from "../pages/ContactUs/ContactUs";
// career
import Career from "../pages/Career/Career";

export default function AppRoutes() {
    const routes = useRoutes([
        {
            element: <Navigate to={'/app'} />,
            index: true
        },
        {
            path: '/app',
            element: <PiWebLayout />,
            children: [
                {
                    element: <Navigate to={'/app/home'} />,
                    index: true
                },
                {
                    path: '/app/home',
                    element: <Home />
                },
                {
                    path : '/app/about-us',
                    element : <AboutUs/>
                },
                {
                    path : '/app/portfolio',
                    element : <Portfolio/>
                },
                {
                    path : '/app/services',
                    element : <Services/>
                },
                {
                    path : '/app/contact-us',
                    element : <ContactUs/>
                },
                {
                    path : '/app/careers',
                    element : <Career/>
                },
            ]
        },
        {
            path: '*',
            element: <PageNotFound />
        }
    ]);
    return routes;
}