import { Box, Grid, Typography } from "@mui/material";

import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

// logo
import logo from '../../assets/pi_logo_2.png';
import contactUS from '../../assets/contact-us.jpg'

const ContactUs = () => {
    return (
        <Grid sx={{ paddingX: { xs: 0, lg: '150px' }, display: 'flex', flexDirection: 'column', gap: { xs: 2, md: 5 } }}>
            <Grid textAlign={'center'}>
                <Typography sx={{ fontSize: { xs: '25px', md: '50px' }, color: '#01a2d4', fontWeight: 600 }}>Contact Us</Typography>
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} textAlign={'justify'}>
                <Typography className="resText">Welcome to the Contacts page of Pascali Technologies – your gateway to connect with our dedicated team of IT professionals. Whether you're a valued client seeking assistance, a prospective partner looking to collaborate, or simply have a question, we're here to help you.
                </Typography>
                <Typography className="resText">
                    Thank you for considering Pascali Technologies as your trusted IT partner. We look forward to hearing from you and assisting you on your journey towards technological excellence.
                </Typography>
            </Grid>
            <img src={contactUS} alt="contactUS" />
            <Box display={'flex'} flexDirection={'column'} textAlign={'justify'} sx={{ maxWidth: { xs: 'unset', md: 300 } }} >
                <Typography className="resText" color={'#01a2d4'} fontWeight={600} gutterBottom>Pascali Technologies Private Limited</Typography>
                <Box display={'flex'} alignItems={'center'} gap={1} sx={{ marginBottom: '3px' }}>
                    <img src={logo} alt="logo" style={{ width: '30px' }} />
                    <Typography color={'#bf1836'} className="resText">Corporate & Sales Office</Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                    <LocationOnIcon sx={{ fontSize: '11px' }} />
                    <Typography className="resText" sx={{ fontSize: '14px' }}>SH 4, Plot No. 66, Gorai Sai Gharkul CHS., Opp. Pepsi Ground, Gorai 2, Borivali West, Mumbai 400 092, Maharashtra, INDIA.</Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                    <LocalPhoneIcon sx={{ fontSize: '11px' }} />
                    <Typography className="resText" sx={{ fontSize: '14px' }}>+91 22 3558 7031, +91 88288 90285</Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                    <EmailIcon sx={{ fontSize: '11px' }} />
                    <Typography className="resText" sx={{ fontSize: '14px' }}>info@pascali.in</Typography>
                </Box>
            </Box>
            <Box display={'flex'} sx={{ gap: { xs: 2, md: 15 }, flexDirection: { xs: 'column', md: 'row' } }} textAlign={'justify'}>
                <Box display={'flex'} flexDirection={'column'} flex={1}>
                    <Box display={'flex'} alignItems={'center'} gap={1} sx={{ marginBottom: '3px' }}>
                        <img src={logo} alt="logo" style={{ width: '30px' }} />
                        <Typography color={'#bf1836'} className="resText">Development Centre - Mumbai</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <LocationOnIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>SH 4, Plot No. 66, Gorai Sai Gharkul CHS., Opp. Pepsi Ground, Gorai 2, Borivali West, Mumbai 400 092, Maharashtra, INDIA.</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <LocalPhoneIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>+91 22 3558 7031, +91 88288 90285</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <EmailIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>info@pascali.in</Typography>
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'column'} flex={1}>
                    <Box display={'flex'} alignItems={'center'} gap={1} sx={{ marginBottom: '3px' }}>
                        <img src={logo} alt="logo" style={{ width: '30px' }} />
                        <Typography color={'#bf1836'} className="resText">Development Centre - Pune</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <LocationOnIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>6, Priyanjali, Sundar Rao Rege Marg, Income Tax Lane, Prabhat road Pune - 411004, Maharashtra, INDIA.</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <LocalPhoneIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>+91 22 3558 7031, +91 88288 90285</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <EmailIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>info@pascali.in</Typography>
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'column'} flex={1}>
                    <Box display={'flex'} alignItems={'center'} gap={1} sx={{ marginBottom: '3px' }}>
                        <img src={logo} alt="logo" style={{ width: '30px' }} />
                        <Typography color={'#bf1836'} className="resText">Development Centre - Gurugram</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <LocationOnIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px',display: { xs: 'block', md: 'none' }  }}>Innohub- Opposite DLF City Court, DLF Phase II, Sector 24 Gurgaon, HR 122002, INDIA.</Typography>
                        <Typography className="resText" sx={{ fontSize: '14px', display: { xs: 'none', md: 'block' } }}>Innohub- Opposite DLF City Court,<br /> DLF Phase II, Sector 24 <br /> Gurgaon, HR 122002, INDIA.</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <LocalPhoneIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>+91 22 3558 7031, +91 88288 90285</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <EmailIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>info@pascali.in</Typography>
                    </Box>
                </Box>
            </Box>
            <Box display={'flex'} sx={{ gap: { xs: 2, md: 17 }, flexDirection: { xs: 'column', md: 'row' } }} textAlign={'justify'}>
                <Box display={'flex'} flexDirection={'column'} flex={1} sx={{ maxWidth: { xs: 'unset', md: 300 } }}>
                    <Box display={'flex'} alignItems={'center'} gap={1} sx={{ marginBottom: '3px' }}>
                        <img src={logo} alt="logo" style={{ width: '30px' }} />
                        <Typography color={'#bf1836'} className="resText">Development Centre - Noida</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <LocationOnIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>Noida: A-53, 1st Floor, A-Block, Sector 16, Noida, UP - 201301, INDIA.</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <LocalPhoneIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>+91 22 3558 7031, +91 88288 90285</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <EmailIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>info@pascali.in</Typography>
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'column'} flex={1} sx={{ maxWidth: { xs: 'unset', md: 300 } }}>
                    <Box display={'flex'} alignItems={'center'} gap={1} sx={{ marginBottom: '3px' }}>
                        <img src={logo} alt="logo" style={{ width: '30px' }} />
                        <Typography color={'#bf1836'} className="resText">Sales Office - London</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <LocationOnIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>1 South End, Great Bookham, Leatherhead.
                            KT23 4SQ, UK.</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <LocalPhoneIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>+91 22 3558 7031, +91 88288 90285</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <EmailIcon sx={{ fontSize: '11px' }} />
                        <Typography className="resText" sx={{ fontSize: '14px' }}>info@pascali.in</Typography>
                    </Box>
                </Box>
            </Box>
            {/* <Grid sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, gap: 2, justifyContent: 'end', alignItems: 'center' }}>
                <Typography>Visitor Count: </Typography>
                <Box className='visiotCountBox'>
                    <Box className='VisitorCounter'>0</Box>
                    <Box className='VisitorCounter'>0</Box>
                    <Box className='VisitorCounter'>0</Box>
                    <Box className='VisitorCounter'>0</Box>
                    <Box className='VisitorCounter'>0</Box>
                    <Box className='VisitorCounter'>0</Box>
                    <Box className='VisitorCounter'>0</Box>
                </Box>
            </Grid> */}
        </Grid>
    )
}
export default ContactUs;